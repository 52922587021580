.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: white; /* Set your desired background color */
    color: black; /* Set your desired text color, e.g., black */
}

.navbar-logo {
    display: flex;
    align-items: center;
    margin: 10px;
}

.navbar-links {
    display: flex;
    align-items: center;
}

.navbar a {
    text-decoration: none;
    color: black; /* Set your desired link color, e.g., black */
    margin: 0 10px; /* Adjust margin as needed */
}

.active-link {
    /* Add your styles for the active link here */
    color: #ff0000; /* Change to your desired text color */
    /* You can add more styles as needed */
    text-decoration: underline;
}

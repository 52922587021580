.App {
    text-align: center;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-container {
    flex-grow: 1;
    padding-bottom: 60px; /* Adjust this value to match your footer height */
}

@font-face {
    font-family: "RobotoSlab"; /* Replace with your font name */
    src: url("./static/fonts/RobotoSlab-Light.ttf") format("truetype"); /* Adjust the path to your font file */
    font-weight: normal;
    font-style: normal;
}
